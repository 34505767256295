// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://release-api.thebranfordgroup.com/api',
  serverUrl: 'https://release-api.thebranfordgroup.com/',
  captchaSiteKey: '6LdapqMmAAAAAE8tAsHjIUbyg7w3H5pTumLQwk3U',
  encryptionKey: 'C45E4CA631211B4461AF578224C0236E',
  websiteUrl: 'https://release.thebranfordgroup.com/',
  websiteTitle: 'The Branford Group',
  gaTrackingId: 'UA-210989-2',
  hubs: {
    bid: 'bidHub',
    adminBid: 'adminBidHub',
    notification: 'notificationHub',
    adminNotification: 'adminNotificationHub',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
